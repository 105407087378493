import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentAlert.figmaUrl.android} codeUrl={checklists.componentAlert.codeUrl.android} checklists={checklists.componentAlert.checklists} mdxType="ChecklistResourcesSection" />
    <p>{`The Alert component is utilized to present a temporary surface that provides a list of options. It displays a brief message or notification to the user within an existing activity.`}</p>
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <br />
To implement Alert, you can use this tag `LgnAlertButton` in the xml file.
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`    var visible by remember {
        mutableStateOf(true)
    }

    LgnAlert(
        title = "Info Title",
        description = "Interactive monetize corporate alignment",
        action = {
            Text(text = "Call To Action")
        },
        visible = visible,
        showCloseIcon = true,
        onClose = {  visible = false}
    )

    if (!visible) {
        Button(onClick = { visible = true }) {
            Text("Show Alert")
        }
    }
`}</code></pre>
    <div className="divi" />
    <h2>{`Themes`}</h2>
    <p>{`There are 4 themes which are available :`}</p>
    <h3>{`Info`}</h3>
    <p>{`The following is properties value to implement Alert `}<inlineCode parentName="p">{`info`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`    var visible by remember {
        mutableStateOf(true)
    }

    LgnAlertInfo(
        title = "Info Title",
        description = "Interactive monetize corporate alignment",
        action = {
            Text(text = "Call To Action")
        },
        visible = visible,
        showCloseIcon = true,
        onClose = {  visible = false}
    )

    if (!visible) {
        Button(onClick = { visible = true }) {
            Text("Show Alert")
        }
    }
`}</code></pre>
    <h3>{`Success`}</h3>
    <p>{`The following is properties value to implement Alert `}<inlineCode parentName="p">{`Success`}</inlineCode>{` .`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`   var visible by remember {
        mutableStateOf(true)
    }

    LgnAlertSuccess(
        title = "Success Title",
        description = "Interactive monetize corporate alignment",
        action = {
            Text(text = "Call To Action")
        },
        visible = visible,
        showCloseIcon = true,
        onClose = { visible = false }
    )

    if (!visible) {
        Button(onClick = { visible = true }) {
            Text("Show Alert")
        }
    }
`}</code></pre>
    <h3>{`Warning`}</h3>
    <p>{`The following is properties value to implement Alert `}<inlineCode parentName="p">{`Warning`}</inlineCode>{` .`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`   var visible by remember {
        mutableStateOf(true)
    }

    LgnAlertWarning(
        title = "Warning Title",
        description = "Interactive monetize corporate alignment",
        action = {
            Text(text = "Call To Action")
        },
        visible = visible,
        showCloseIcon = true,
        onClose = { visible = false }
    )

    if (!visible) {
        Button(onClick = { visible = true }) {
            Text("Show Alert")
        }
    }
`}</code></pre>
    <h3>{`Error`}</h3>
    <p>{`The following is properties value to implement Alert `}<inlineCode parentName="p">{`Error`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`   var visible by remember {
        mutableStateOf(true)
    }

    LgnAlertError(
        title = "Error Title",
        description = "Interactive monetize corporate alignment",
        action = {
            Text(text = "Call To Action")
        },
        visible = visible,
        showCloseIcon = true,
        onClose = { visible = false }
    )

    if (!visible) {
        Button(onClick = { visible = true }) {
            Text("Show Alert")
        }
    }
`}</code></pre>
    <div className="divi" />
    <h2>{`Attributes`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`title`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set the title of Alert`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`description`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set the description of Alert`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`action`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Composable`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set more component in Alert`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`visible`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Boolean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To show or hide an Alert`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`showCloseIcon`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Boolean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To display or remove the close icon`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`onClose`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Function`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Action for close Alert`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`icon`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Composable`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set Custom Icon`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colors`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`LgnAlertColors`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set the color of the Alert By LgnAlertDefaults.infoColors()`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`modifier`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Modifier`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`[Modifier]`}{` to be applied to the alert`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      